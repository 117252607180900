import React from 'react';

const Resume = () => {
    console.log('ok')
    return (
        <div>
            resume
        </div>
    );
};

export {Resume};