import React from "react"

import { Layout, SEO } from "../components/shared"
import { Resume } from "../components/Resume/Resume"

const HireMePage = (props) => (
  <>
    <Layout locationPath={props.location.pathname}>
        <SEO title="Hire Me, Get Support | Resume | CV | Frontend Developer | Backend Developer | mdmostafa.com" />
        <Resume />
      </Layout>
  </>
)

export default HireMePage;